<template>
  <div>
    <loader v-if="loading" ></loader>
    <p class="center" v-else-if="!fullRecordById">
      {{$t('recordDetail.RecordId')}}"<strong>{{$route.params.id}}</strong>" {{$t('recordDetail.isAbsentInDatabase')}}
    </p>
    <div v-else>
      <div class="breadcrumb-wrap">
        <router-link to="/history" class="breadcrumb">{{$t('recordDetail.History')}}</router-link>
        <a @click.prevent class="breadcrumb">
          {{$t(`recordDetail.${fullRecordById.type}`)}}
        </a>
      </div>
      <div class="row">
        <div class="col s12 m6">
          <div
            class="card"
            :class="[fullRecordById.typeColor]"
          >
            <div class="card-content white-text">
              <p>{{$t('recordDetail.Description')}}: {{fullRecordById.description}}</p>
              <p>{{$t('recordDetail.Amount')}}: {{fullRecordById.amount | currencyView}}</p>
              <p>{{$t('recordDetail.Category')}}: {{fullRecordById.categoryName}}</p>

              <small>{{fullRecordById.date | date('datetime')}}</small>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Detail-record',
  data () {
    return {
      loading: true,
      fullRecordById: null
    }
  },
  metaInfo () {
    return {
      // if no subcomponents specify a metaInfo.title, this title will be used
      title: 'Detail'
    }
  },
  async mounted () {
    const id = this.$route.params.id
    const recordById = await this.$store.dispatch('fetchRecordById', id)
    const categoryById = await this.$store.dispatch('fetchCategoryById', recordById.categoryId)
    this.fullRecordById = {
      ...recordById,
      categoryName: categoryById.name,
      type: recordById.type === 'income' ? 'Income' : 'Outcome',
      typeColor: recordById.type === 'income' ? 'green' : 'red'
    }
    this.loading = false
  }
}
</script>

<style scoped>

</style>
